import { Skeleton, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cookies from "../utilities/Cookies";
import requests from "../handlers/requests";
import Toast from "../components/Toast";
import { isMobile } from "react-device-detect";

const Settings = ({ title }) => {
    document.querySelector("title").innerHTML = title
    const navigate = useNavigate()

    const [user, setUser] = useState(null)
    const stringify_user = cookies.getCookies("user")

    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const getUser = (id) => {
        requests.makeGet(
            process.env.REACT_APP_SERVER + `user?uid=${id}`,
            setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                setUser(res.data)
            },
            "success"
        )
    }

    useEffect(() => {
        if (stringify_user.length < 10) {
            navigate('/')
        } else {
            getUser(JSON.parse(stringify_user).id)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className="settings-page">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Typography>
                        Address information
                    </Typography>
                    <Spacebox padding="5px" />
                    {user && <Spacebox padding="20px" style={{ border: "1px solid #ffffff20", borderRadius: "20px" }}>
                        <CustomGrid grid={isMobile ? 1 : 3} gap="10px">
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    country
                                </Typography>
                                <Typography>
                                    {user.country}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    State
                                </Typography>
                                <Typography>
                                    {user.state}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    city
                                </Typography>
                                <Typography>
                                    {user.city}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    home address
                                </Typography>
                                <Typography>
                                    {user.address}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    Zip code
                                </Typography>
                                <Typography>
                                    {user.zip}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    submitted document
                                </Typography>
                                <Typography>
                                    <a href={user.bank_statement} target="_blank" rel="noreferrer"><u>View Bank Statement</u></a>
                                </Typography>
                            </div>
                        </CustomGrid>
                    </Spacebox>}
                    {loading && (
                        <Skeleton variant="rounded" sx={{width: '100%', height: '100px'}}  />
                    )}
                </Spacebox>
            </div>
            <Spacebox padding="20px" />
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Typography>
                        Identity verification
                    </Typography>
                    <Spacebox padding="5px" />
                    {user && <Spacebox padding="20px" style={{ border: "1px solid #ffffff20", borderRadius: "20px" }}>
                        <CustomGrid grid={isMobile ? 1 : 3} gap="10px">
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    document type
                                </Typography>
                                <Typography>
                                    {user.gov_id}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    Issuing country
                                </Typography>
                                <Typography>
                                    {user.country}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                    submitted document
                                </Typography>
                                <Typography>
                                    <a href={user.id_front} target="_blank" rel="noreferrer"><u>View {user.gov_id}</u></a>
                                </Typography>
                            </div>
                        </CustomGrid>
                    </Spacebox>}
                    {loading && (
                        <Skeleton variant="rounded" sx={{width: '100%', height: '100px'}}  />
                    )}
                </Spacebox>
            </div>
        </div>
    );
}

export default Settings;