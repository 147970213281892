import { Avatar, Divider, IconButton, Typography } from "@mui/material";
import Flexbox from "./Flexbox";
import Spacebox from "./styles/Spacebox";
import { useNavigate } from "react-router-dom";
import cookies from "../utilities/Cookies";
import { useEffect, useState } from "react";
import intlTelInput from "../utilities/util";
import { Close, Menu } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const Header = ({ openMenu, setOpenMenu }) => {
    const navigate = useNavigate()
    const stringify_user = cookies.getCookies('user')
    const [user, setUser] = useState(null)

    const [openPopup, setOpenPopup] = useState(false)

    const popup_menus = [
        {
            name: "Your Profile",
            icon: "/assets/user.png",
            path: '/dashboard/profile',
            action: () => {
                navigate('/dashboard/profile')
                setOpenPopup(false)
            }
        },
        {
            name: "Account Settings",
            icon: "/assets/settings.png",
            path: '/dashboard/settings',
            action: () => {
                navigate('/dashboard/settings')
                setOpenPopup(false)
            }
        },
        {
            name: "Contact Support",
            icon: "/assets/message.png",
            path: '',
            action: () => {
                window.location.href = 'mailto:support@tasksmania.com'
            }
        },
    ]

    const getHeaderHeight = () => {
        return document.querySelector('div.header').offsetHeight
    }

    const getFlag = country => {
        let user_country = intlTelInput.countries.filter(cnty => cnty.name === country)
        return "/flags/" + user_country[0].cca2 + ".png"
    }

    useEffect(() => {
        if (stringify_user.length > 10) {
            setUser(JSON.parse(stringify_user))
        }
        // eslint-disable-next-line
    }, [])
    return (
        <div className="header" style={{ borderBottom: '2px solid #434d5b33', position: 'sticky', top: 0, background: 'var(--dark)', zIndex: 100 }}>
            {user && <Spacebox padding="20px">
                <Flexbox justifyContent="space-between">
                    {isMobile && <IconButton onClick={() => setOpenMenu(!openMenu)}>
                        {openMenu && <Close />}
                        {!openMenu && <Menu />}
                    </IconButton>}
                    {!isMobile && <div>
                        <Typography variant="h5">
                            Hello, {user.firstname} 👋🏼
                        </Typography>
                        <small style={{ opacity: .5 }}>You have a few tasks waiting to be completed</small>
                    </div>}
                    <Flexbox alignItems="center">
                        <Avatar style={{ width: isMobile ? 20 : 30, height: isMobile ? 20 : 30 }} src={getFlag(user.country)} alt={user.country} />
                        <Spacebox padding="10px" />
                        <div onClick={() => setOpenPopup(!openPopup)} style={{ cursor: 'pointer' }}>
                            <Avatar sx={{ backgroundColor: "var(--primary)", width: isMobile ? 30 : 40, height: isMobile ? 30 : 40 }}>
                                <small style={isMobile ? {fontSize: "10px" } : {}}>{(user.firstname[0]).toUpperCase() + (user.lastname[0]).toUpperCase()}</small>
                            </Avatar>
                        </div>
                    </Flexbox>
                </Flexbox>
            </Spacebox>}
            {openPopup && <div className="menu-popup" style={{ borderRadius: '20px', top: (getHeaderHeight()) + "px", position: 'fixed', right: '20px', width: '300px', zIndex: 1000, background: 'var(--dark)' }}>
                <Spacebox padding="20px">
                    <Flexbox alignItems="center">
                        <Avatar sx={{ backgroundColor: "var(--primary)", cursor: 'pointer' }}>
                            <small>{(user.firstname[0]).toUpperCase() + (user.lastname[0]).toUpperCase()}</small>
                        </Avatar>
                        <Spacebox padding="5px" />
                        <div>
                            <Typography variant="h5">
                                {user.firstname} {user.lastname}
                            </Typography>
                            <small style={{ opacity: .5 }}>{user.email}</small>
                        </div>
                    </Flexbox>
                </Spacebox>
                <Divider />
                <Spacebox padding="10px 20px">
                    {popup_menus.map((menu, index) => (
                        <div key={index}>
                            <div onClick={menu.action} style={{ cursor: 'pointer', transition: '.5s ease', padding: '15px 10px', borderRadius: '10px' }} className="popup-menu">
                                <Flexbox alignItems="center">
                                    <img src={menu.icon} style={{ width: '20px', filter: 'invert(1)' }} alt={menu.name} />
                                    <Spacebox padding="10px" />
                                    <span>{menu.name}</span>
                                </Flexbox>
                            </div>
                        </div>
                    ))}
                </Spacebox>
                <Divider />
                <Spacebox padding=" 10px 20px">
                    <div>
                        <div onClick={() => {
                            cookies.deleteCookies("user")
                            navigate('/')
                        }} style={{ cursor: 'pointer', transition: '.5s ease', padding: "15px 10px", borderRadius: '10px' }} className="popup-menu">
                            <Flexbox alignItems="center">
                                <img src="/assets/logout.png" style={{ width: '20px', filter: 'invert(1)' }} alt="logout" />
                                <Spacebox padding="10px" />
                                <span>Logout</span>
                            </Flexbox>
                        </div>
                        <Spacebox padding="10px" />
                    </div>
                </Spacebox>
            </div>}
        </div>
    );
}

export default Header;