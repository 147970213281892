import { useEffect, useState } from "react";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import cookies from "../utilities/Cookies";
import { Skeleton, Typography } from "@mui/material";
import CustomGrid from "../components/styles/Grid";
import { useNavigate } from "react-router-dom";
import { ContentCopy } from "@mui/icons-material";
import requests from "../handlers/requests";
import Toast from "../components/Toast";
import { isMobile } from "react-device-detect";

const Profile = ({ title }) => {
    document.querySelector("title").innerHTML = title
    const navigate = useNavigate()

    const [currentTab, setCurrentTab] = useState(0)
    const [user, setUser] = useState(null)
    const stringify_user = cookies.getCookies("user")
    const navigators = [
        {
            name: 'Basic info',
        },
        {
            name: 'Referral',
        },
    ]

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(true)

    const [noOfRef, setNoOfRef] = useState(null)
    const [completeRef, setCompletedRef] = useState(null)
    const [earning, setEarning] = useState(null)

    const getActivites = (id) => {
        requests.makeGet(
            process.env.REACT_APP_SERVER + `affiliate-details?uid=${id}`,
            setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                setNoOfRef(res.ref_no)
                setCompletedRef(res.ref_comp)
                setEarning(res.ref_comp * 15)
            }, 
            "Successful"
        )
    }


    useEffect(() => {
        if (stringify_user.length < 10) {
            navigate('/')
        } else {
            setUser(JSON.parse(stringify_user))
            getActivites(JSON.parse(stringify_user).id)
        }
        // eslint-disable-next-line
    }, [])



    return (
        <div className="profile-body">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <div className="navigators">
                        <Flexbox alignItems="center" >
                            {navigators.map((nav, index) => (
                                <div style={{ backgroundColor: index === currentTab && '#00000070', boxShadow: index === currentTab && 'rgba(86, 97, 107, 0.1) 0px 1px 2px 0px' }} className="cust-btn" key={index} onClick={() => setCurrentTab(index)}>
                                    <span>{nav.name}</span>
                                </div>
                            ))}
                        </Flexbox>
                    </div>
                </Spacebox>
            </div>
            <Spacebox padding="20px" />
            {(currentTab === 0 && user) && <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Typography>
                        Personal Information
                    </Typography>
                    <Spacebox padding="10px" />
                    <CustomGrid grid={isMobile ? 1 : 2} gap="20px">
                        <Spacebox padding="20px" style={{ border: "1px solid #ffffff20", borderRadius: "20px" }}>
                            <CustomGrid gap="20px" grid={isMobile ? 1 : 2}>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        first name
                                    </Typography>
                                    <Typography>
                                        {user.firstname}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        last name
                                    </Typography>
                                    <Typography>
                                        {user.lastname}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        email address
                                    </Typography>
                                    <Typography>
                                        {user.email}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        date of birth
                                    </Typography>
                                    <Typography>
                                        {user.dob}
                                    </Typography>
                                </div>
                            </CustomGrid>
                        </Spacebox>
                        <Spacebox padding="20px" style={{ border: "1px solid #ffffff20", borderRadius: "20px" }}>
                            <CustomGrid gap="20px" grid={isMobile ? 1 : 2}>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        Bank Name
                                    </Typography>
                                    <Typography>
                                        {user.bank_name}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        Account Name
                                    </Typography>
                                    <Typography>
                                        {user.bank_account_name}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        Account Number
                                    </Typography>
                                    <Typography>
                                        {user.bank_account_number}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography style={{ letterSpacing: '3px', textTransform: 'uppercase', opacity: 1, fontSize: "10px" }} className="grey-text" >
                                        Bank Verification Number
                                    </Typography>
                                    <Typography>
                                        {user.bvn ? user.bvn : "Not Set"}
                                    </Typography>
                                </div>
                            </CustomGrid>
                        </Spacebox>
                    </CustomGrid>
                </Spacebox>
            </div>}
            {(currentTab === 1 && user) && <div>
                <div className="gradient-animate" style={{ borderRadius: '10px' }}>
                    <Spacebox padding="20px">
                        <CustomGrid gap="10px" grid={isMobile ? 1 : 2}>
                            <div>
                                <Typography variant="h5">
                                    Refer a friend and earn $15
                                </Typography>
                                <Typography>
                                    Share your referral code and get $15 when whoever you refer completes their sign up process
                                </Typography>
                                <Spacebox padding="10px" />
                                <div className="glass pointer" onClick={() => {
                                    window.navigator.clipboard.writeText(`https://app.tasksmania.com/register?ref=${user.id}`)
                                    setToastMsg("Copied")
                                    setSeverity("success")
                                    setOpen(true)
                                }} style={{ width: 'fit-content', borderRadius: '10px' }}>
                                    <Spacebox padding="10px">
                                        <Flexbox alignItems={"center"}>
                                            {user.id}
                                            <Spacebox padding="5px" />
                                            <ContentCopy style={{ fontSize: '15px' }} />
                                        </Flexbox>
                                    </Spacebox>
                                </div>
                            </div>
                        </CustomGrid>
                    </Spacebox>
                </div>
                <Spacebox padding="10px" />
                <div className="mode" style={{ borderRadius: '10px' }}>
                    <Spacebox padding="20px">
                        {!loading &&<CustomGrid gap="10px" grid={isMobile ? 1 : 3}>
                            <div>
                                <Typography style={{opacity: .5, fontSize: '15px'}}>
                                    No. of referrals
                                </Typography>
                                <Typography>
                                    {noOfRef ?? 0}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{opacity: .5, fontSize: '15px'}}>
                                    Completed Process
                                </Typography>
                                <Typography>
                                    {completeRef ?? 0}
                                </Typography>
                            </div>
                            <div>
                                <Typography style={{opacity: .5, fontSize: '15px'}}>
                                    Total earnings
                                </Typography>
                                <Typography>
                                    ${earning ?? "0"}.00
                                </Typography>
                            </div>
                        </CustomGrid>}
                        {loading && <Skeleton variant="rounded" sx={{width: '100%', height: '40px'}} />}
                    </Spacebox>
                </div>
            </div>}
            <Spacebox padding="10px" />
        </div>
    );
}

export default Profile;