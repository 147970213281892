import { Divider, IconButton, Step, StepLabel, Stepper, Typography } from "@mui/material";
import Toast from "../components/Toast";
import CustomButton from "../components/styles/Custombutton";
import { useEffect, useState } from "react";
import Flexbox from "../components/Flexbox";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import { ArrowBackOutlined } from "@mui/icons-material";
import { DotSpinner } from "@uiball/loaders";
import requests from "../handlers/requests";
import cookies from "../utilities/Cookies";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Signup = ({ title }) => {

    document.querySelector('title').innerHTML = title
    const urlParams = new URLSearchParams(window.location.search)
    const aff_ref = urlParams.get("ref")

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)

    const [country, setCountry] = useState('Ghana')
    const [email, setEmail] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [dob, setDOB] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState('Male')
    const [callCode, setCallCode] = useState('233')
    const [ref, setRef] = useState('')
    const [disableRef, setDisableRef] = useState(false)

    const verify = () => {
        const minYear = (new Date()).getFullYear() - 18

        if (!email.includes("@")) {
            return { success: false, msg: "Invalid email" }
        } else if (firstname === "") {
            return { success: false, msg: "Please provide a valid first name" }
        } else if (lastname === "") {
            return { success: false, msg: "Provide a valid last name" }
        } else if (dob === "") {
            return { success: false, msg: "Enter your date of birth" }
        } else if (phone === "") {
            return { success: false, msg: "provide a valid phone number" }
        } else if (password.length < 8) {
            return { success: false, msg: "password too short" }
        } else if (password !== confirmPassword) {
            return { success: false, msg: "password mismatch" }
        } else if (parseInt(dob.split("-")[0]) > minYear) {
            return { success: false, msg: "must be over 18 to register" }
        }

        return { success: true }
    }

    const countries = [
        "Botswana",
        "Ghana",
        "Kenya",
        "Lesotho",
        "Nigeria",
        "Rwanda",
        "South Africa",
        "Tanzania",
        "Uganda",
        "Zambia"
    ]

    const country_codes = [
        "267",
        "233",
        "254",
        "266",
        "234",
        "250",
        "27",
        "255",
        "256",
        "260"
    ]

    const genders = [
        "Male",
        "Female"
    ]

    const steps = [
        'Contact Information',
        'Basic information',
        'Create Password',
    ];

    const createAccount = () => {
        if (verify().success) {
            setLoading(true)
            requests.makePost(
                process.env.REACT_APP_SERVER + 'register',
                {
                    email: email.toLowerCase(), firstname, lastname, country, gender, password,
                    phone: `(+${callCode}) ` + phone, dob, ref, complete: false
                },
                setOpen,
                setSeverity,
                setToastMsg,
                setLoading,
                (res) => {
                    cookies.setCookies("user", JSON.stringify(res.data), 0.5)
                    setTimeout(() => {
                        window.location.href = `https://www.tasksmania.com/contract?email=${email}&firstname=${firstname}&lastname=${lastname}`
                    }, 4000);
                },
                "Account created successfully"
            )
        } else {
            setToastMsg(verify().msg)
            setSeverity('error')
            setOpen(true)
        }
    }

    useEffect(() => {
        console.log(aff_ref)
        if (aff_ref !== null && aff_ref.length > 10) {
            setRef(aff_ref)
            setDisableRef(true)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Flexbox className="full">
                <div className="mode hide-on-med-and-down" style={{ height: '100%', width: '50%' }}>
                    <Spacebox padding="20px">
                        <Spacebox padding="20px" style={{ textAlign: 'center' }}>
                            <img src="/logo-white.png" alt="logo" style={{ width: '150px' }} />
                        </Spacebox>
                        <Spacebox padding="10px" />
                        <CustomGrid gap="20px" grid={2}>
                            <img style={{borderRadius: "10px", width: '100%' }} src="/assets/tech-girl.jpg" alt="banner" />
                            <img style={{borderRadius: "10px", marginTop: "30px", width: '100%' }} src="/assets/tech-girl2.jpg" alt="banner" />
                        </CustomGrid>
                        <Spacebox padding="10px" />
                        <Typography fontSize="30px" textAlign="center">
                            <b>Get paid for your perspectives</b>
                        </Typography>
                        <Spacebox padding="4px" />
                        <Typography textAlign="center">
                            Influence the biggest brands, latest technologies, and innovative companies while earning money online in your spare time.
                        </Typography>
                    </Spacebox>
                </div>
                <Flexbox justifyContent="center" alignItems="center" style={{ maxHeight: '100%', minHeight: "100%", width: '100%', overflowY: 'scroll' }}>
                    <Spacebox padding="20px" className="mid" style={{ margin: 'auto' }}>
                        <div style={{ width: '100%', borderRadius: '20px' }} className={isMobile ? "" : "mode"}>
                            <Spacebox padding={isMobile ? "0px" : "20px"}>
                                <Flexbox alignItems="center">
                                    {activeStep > 0 && <IconButton onClick={() => setActiveStep(activeStep - 1)}>
                                        <ArrowBackOutlined />
                                    </IconButton>}
                                    <Divider orientation="vertical" flexItem />
                                    <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>
                                                    {label}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Flexbox>
                            </Spacebox>
                        </div>
                        <Spacebox padding="5px" />
                        <div style={{ width: '100%', borderRadius: '20px' }} className={isMobile ? "" : "mode"}>
                            <Spacebox padding={isMobile ? "0px" : "20px"}>
                                {activeStep === 0 && <div>
                                    <Spacebox padding="10px">
                                        <Typography className="bold" variant="h5">
                                            Contact Information
                                        </Typography>
                                    </Spacebox>
                                    <Divider light />
                                    <Spacebox padding="10px">
                                        <Spacebox padding="5px">
                                            <small>Email</small>
                                        </Spacebox>
                                        <input
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            placeholder="Enter your email"
                                        />
                                        <Spacebox padding="10px" />
                                        <Spacebox padding="5px">
                                            <small>Country</small>
                                        </Spacebox>
                                        <select
                                            value={country}
                                            onChange={(e) => {
                                                setCountry(e.target.value)
                                                setCallCode(country_codes[countries.indexOf(e.target.value)])
                                            }}
                                            name="country"
                                            id="country"
                                        >
                                            {countries.map((country) => (
                                                <option key={country} value={country}>{country}</option>
                                            ))}
                                        </select>
                                        <Spacebox padding="10px" />
                                        <Spacebox padding="5px">
                                            <small>Phone Number</small>
                                        </Spacebox>
                                        <Flexbox alignItems="center">
                                            <Flexbox alignItems="center">
                                                <select value={callCode} onChange={(e) => setCallCode(e.target.value)} name="call-code" id="call-code">
                                                    {country_codes.map((country, index) => (
                                                        <option key={index} value={country}>
                                                            +{country}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Flexbox>
                                            <Spacebox padding="2.5px" />
                                            <input
                                                type="tel"
                                                name="phone"
                                                id="phone"
                                                placeholder="Enter your phone number"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </Flexbox>
                                        <Spacebox padding="10px" />
                                        <CustomButton style={{ backgroundColor: "var(--primary)", padding: "15px 0px", color: 'white', borderRadius: "10px" }} className="fullwidth" handleClick={() => setActiveStep(1)}>
                                            <span>Continue</span>
                                        </CustomButton>
                                    </Spacebox>
                                </div>}
                                {activeStep === 1 && <div>
                                    <Spacebox padding="10px">
                                        <Typography className="bold" variant="h5">
                                            Set up your account
                                        </Typography>
                                    </Spacebox>
                                    <Divider light />
                                    <Spacebox padding="10px">
                                        <CustomGrid grid={2} gap="20px" >
                                            <div>
                                                <Spacebox padding="5px">
                                                    <small>Legal First Name</small>
                                                </Spacebox>
                                                <input
                                                    value={firstname}
                                                    onChange={(e) => setFirstname(e.target.value)}
                                                    placeholder="Enter your legal first name"
                                                    type="text"
                                                />
                                            </div>
                                            <div>
                                                <Spacebox padding="5px">
                                                    <small>Legal Last Name</small>
                                                </Spacebox>
                                                <input
                                                    value={lastname}
                                                    onChange={(e) => setLastname(e.target.value)}
                                                    placeholder="Enter your legal last name"
                                                    type="text"
                                                />
                                            </div>
                                        </CustomGrid>
                                        <Spacebox padding="10px" />
                                        <CustomGrid grid={2} gap="20px">
                                            <div>
                                                <Spacebox padding="5px">
                                                    <small>Date Of Birth</small>
                                                </Spacebox>
                                                <input
                                                    value={dob}
                                                    onChange={(e) => setDOB(e.target.value)}
                                                    type="date"
                                                />
                                            </div>
                                            <div>
                                                <Spacebox padding="5px">
                                                    <small>Gender</small>
                                                </Spacebox>
                                                <select
                                                    name="gender"
                                                    id="gender"
                                                    value={gender}
                                                    onChange={(e) => setGender(e.target.value)}
                                                >
                                                    {genders.map(gender => (
                                                        <option key={gender} value={gender}>{gender}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </CustomGrid>
                                        <Spacebox padding="10px" />
                                        <Spacebox padding="5px">
                                            <small>Referral Code (Optional)</small>
                                        </Spacebox>
                                        <input
                                            value={ref}
                                            onChange={(e) => setRef(e.target.value)}
                                            type="text"
                                            disabled={disableRef}
                                            placeholder="Enter referral code"
                                        />
                                        <Spacebox padding="10px" />
                                        <CustomButton style={{ backgroundColor: "var(--primary)", padding: "15px 0px", color: 'white', borderRadius: "10px" }} className="fullwidth" handleClick={() => setActiveStep(2)}>
                                            <span>Continue</span>
                                        </CustomButton>
                                    </Spacebox>
                                </div>}
                                {activeStep === 2 && <div>
                                    <Spacebox padding="10px">
                                        <Typography className="bold" variant="h5">
                                            Create Password
                                        </Typography>
                                    </Spacebox>
                                    <Divider light />
                                    <Spacebox padding="10px">
                                        <Spacebox padding="5px">
                                            <small>Password</small>
                                        </Spacebox>
                                        <input
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            type="password"
                                            placeholder="Enter password"
                                        />
                                        <Spacebox padding="5px">
                                            <small style={{ opacity: .5 }} >Password must be over 8 characters long</small>
                                        </Spacebox>
                                        <Spacebox padding="5px" />
                                        <Spacebox padding="5px">
                                            <small>Confirm Password</small>
                                        </Spacebox>
                                        <input
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            type="password"
                                            placeholder="Enter password confirmation"
                                        />
                                        <Spacebox padding="10px" />
                                        <CustomButton style={{ backgroundColor: "var(--primary)", padding: "15px 0px", color: 'white', borderRadius: "10px" }} className="fullwidth" handleClick={() => createAccount()}>
                                            {!loading && <span>Create Account</span>}
                                            {loading && <Flexbox justifyContent="center">
                                                <DotSpinner size={20} color="white" />
                                            </Flexbox>}
                                        </CustomButton>
                                    </Spacebox>
                                </div>}
                            </Spacebox>
                        </div>
                        <Spacebox padding="10px">
                            <Typography textAlign="center">
                                Already have an account? <Link to="/"><u>Sign In</u></Link>
                            </Typography>
                        </Spacebox>
                        <Spacebox padding="20px" />
                    </Spacebox>
                </Flexbox>

            </Flexbox>
        </div>
    );
}

export default Signup;