import { Outlet, useNavigate } from "react-router-dom";
// import Header from "./Header";
import Spacebox from "./styles/Spacebox";
import { useEffect, useState } from "react";
import Flexbox from "./Flexbox";
import { Typography } from "@mui/material";
import cookies from "../utilities/Cookies";
import { DotSpinner } from '@uiball/loaders';
import Sidebar from "./Sidebar";
import Header from "./Header";
import { isMobile } from "react-device-detect";

const Layout = () => {

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openMenu, setOpenMenu] = useState(false)

    const navigate = useNavigate()


    useEffect(() => {
        const stringify_user = cookies.getCookies('user');
        if (stringify_user.length < 10 || stringify_user === undefined || stringify_user === null) {
            navigate('/');
        }
        setError(false)
        setLoading(false)
        //eslint-disable-next-line
    }, [])

    return (
        <div>
            {error && (
                <div className="error">
                    <Spacebox padding="20px" />
                    <Typography>| An error occured </Typography>
                </div>
            )}
            {loading && (
                <div>
                    <Spacebox padding="20px" />
                    <Flexbox justifyContent="center" alignItems="center">
                        <DotSpinner color="white" size={30} />
                    </Flexbox>
                </div>
            )}
            {(!error && !loading) && (
                <Flexbox alignItems="flex-start">
                    <Sidebar openMenu={openMenu} setOpenMenu={setOpenMenu} />
                    <div style={{ width: isMobile ? "100%" : "calc(100% - 250px)" }}>
                        <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />
                        <Spacebox padding="20px">
                            <Outlet />
                        </Spacebox>
                    </div>
                </Flexbox>
            )}
        </div>
    );
}

export default Layout;