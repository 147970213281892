import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import MetaMap from './pages/Metamap';
import Tasks from './pages/Tasks';
import Payments from './pages/Payments';
import Statements from './pages/Statements';
import Profile from './pages/Profile';
import Settings from './pages/Settings';

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3773F5'
      },
      secondary: {
        main: '#7badf9'
      }
    }
  })


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path='/' element={<Login title="Login | Tasksmania" />} />
          <Route exact path='/login' element={<Login title="Login | Tasksmania" />} />
          <Route exact path='/register' element={<Signup title="Create an account | Tasksmania" />} />
          <Route exact path='/metamap' element={<MetaMap title="Verify Identity | Tasksmania" />} />
          <Route exact path="/dashboard" element={<Layout />}>
             <Route exact path="" element={<Dashboard title="Dashboard | Tasksmania" />} />
             <Route exact path="tasks" element={<Tasks title="Tasks | Tasksmania" />} />
             <Route exact path="payments" element={<Payments title="Payments | Tasksmania" />} />
             <Route exact path="statements" element={<Statements title="Account Statements | Tasksmania" />} />
             <Route exact path="profile" element={<Profile title="Profile | Tasksmania" />} />
             <Route exact path="settings" element={<Settings title="Account Settings | Tasksmania" />} />
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
