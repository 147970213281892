const countriesAndStates = {
    "Botswana": [
        "Central District",
        "Ghanzi District",
        "Kgalagadi District",
        "Kgatleng District",
        "Kweneng District",
        "North East District",
        "North West District",
        "South East District",
        "Southern District"
    ],
    "Ghana": [
        "Ashanti",
        "Brong Ahafo",
        "Central",
        "Eastern",
        "Greater Accra",
        "Northern",
        "Upper East",
        "Upper West",
        "Volta",
        "Western",
        "Oti",
        "North East",
        "Savannah",
        "Ahafo"
    ],
    "Kenya": [
        "Baringo",
        "Bungoma",
        "Busia",
        "Embu",
        "Garissa",
        "Homa Bay",
        "Isiolo",
        "Kiambu",
        "Kirinyaga",
        "Kisii",
        "Kitui",
        "Kajiado",
        "Kakamega",
        "Kericho",
        "Kilifi",
        "Kwale",
        "Laikipia",
        "Lamu",
        "Machakos",
        "Meru",
        "Migori",
        "Marsabit",
        "Mombasa",
        "Murang'a",
        "Nairobi",
        "Nakuru",
        "Narok",
        "Nyeri",
        "Nyandarua",
        "Siaya",
        "Taita-Taveta",
        "Trans Nzoia",
        "Turkana",
        "Uasin Gishu",
        "Vihiga",
        "Wajir",
    ],
    "Lesotho": [
        "Berea",
        "Butha-Buthe",
        "Leribe",
        "Mafeteng",
        "Maseru",
        "Mohale's Hoek",
        "Mokhotlong",
        "Qacha's Nek",
        "Quthing",
        "Thaba-Tseka"
    ],
    "Nigeria": [
        "Abia",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nasarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara"
    ],
    "Rwanda": [
        "Eastern Province",
        "Northern Province",
        "Southern Province",
        "Western Province"
    ],
    "South Africa": [
        "Eastern Cape",
        "Free State",
        "Gauteng",
        "KwaZulu-Natal",
        "Limpopo",
        "Mpumalanga",
        "North West",
        "Northern Cape",
        "Western Cape"
    ],
    "Tanzania": [
        "Arusha",
        "Dar es Salaam",
        "Dodoma",
        "Iringa",
        "Kagera",
        "Kigoma",
        "Kilimanjaro",
        "Lindi",
        "Manyara",
        "Mara",
        "Mbeya",
        "Morogoro",
        "Mtwara",
        "Mwanza",
        "Pemba North",
        "Pemba South",
        "Pwani",
        "Rukwa",
        "Ruvuma",
        "Shinyanga Rural",
        "Shinyanga Urban",
        "Simiyu",
        "Singida",
        "Tabora",
        "Tanga"
    ],
    "Uganda": [
        "Abim",
        "Adjumani",
        "Agago",
        "Amuria",
        "Amuru",
        "Apac",
        "Arua",
        "Atungo",
        "Balega",
        "Bundibugyo",
    ],
    "Zambia": [
        "Central Province",
        "Copperbelt Province",
        "Eastern Province",
        "Luapula Province",
        "Lusaka Province",
        "Muchinga Province",
        "North-Western Province",
        "Northern Province",
        "Southern Province",
        "Western Province"
    ]
}


export default countriesAndStates