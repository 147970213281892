import { Avatar, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import CustomGrid from "../components/styles/Grid";
import cookies from "../utilities/Cookies";
import { useEffect, useState } from "react";
import CustomButton from "../components/styles/Custombutton";
import Flexbox from "../components/Flexbox";
import { DotSpinner } from "@uiball/loaders";
import banks from "../utilities/bank";
import Toast from "../components/Toast";
import requests from "../handlers/requests";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import za_banks from "../utilities/za_banks";

const Dashboard = ({ title }) => {

    document.querySelector('title').innerHTML = title
    const stringify_user = cookies.getCookies('user')

    const [user, setUser] = useState(null)

    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [loading, setLoading] = useState(false)

    const [onboarding, setOnboarding] = useState(false)
    const [bankDetails, setBankDetails] = useState(false)
    const [bankName, setBankName] = useState('')
    const [bankAccountNumber, setBankAccountNumber] = useState('')
    const [bankAccountName, setBankAccountName] = useState('')
    const [bvn, setBVN] = useState('')
    const [disableInput, setDisableInput] = useState(false)

    const [step, setStep] = useState(1)

    const runAccountCheck = number => {
        if (number.length === 10) {
            setDisableInput(true)
            setLoading(true)

            fetch(`https://nubapi.com/api/verify?account_number=${number}&bank_code=${banks.codes[bankName]}`, {
                headers: {
                    "Authorization": "Bearer LyL213oUei7bt36fHWb5rXfR33I5rd2JDXi6D1jy056500e0",
                    "Content-Type": "application/json"
                }
            }).then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw Error("An error occured from resource: code" + res.status + " Error: " + res)
                }
            }).then(res => {
                if (res.account_name) {
                    setBankAccountName(res.account_name)
                    setLoading(false)
                } else if (res.message) {
                    setToastMsg(res.message)
                    setSeverity("error")
                    setOpen(true)
                    throw Error(res.message)
                } else {
                    throw Error(res)
                }
            }).catch(err => {
                console.log("An error occured: " + err.message)
                if ((err.message).includes("Account information"))
                    setToastMsg("Account information not available, Verify details and try again")
                else
                    setToastMsg("An error occured")
                setSeverity("error")
                setOpen(true)
                setLoading(false)
                setDisableInput(false)
            })
        }
        setBankAccountNumber(number)
        // "0457929678"
    }

    const checkCountry = () => (user.country).toLowerCase() === 'south africa' ? true : false

    const saveBankDetails = () => {
        if (checkCountry()) {
            setBVN('0000000000')
            setBankAccountName((user.firstname + " " + user.lastname))
        }

        if (bankAccountName !== null && bankAccountName !== '' && bankAccountNumber !== '' && (bvn !== '' && bvn.length > 9)) {
            setLoading(true)
            requests.makePost(
                process.env.REACT_APP_SERVER + 'update-user?uid=' + user.id,
                { bank_name: bankName, bank_account_number: bankAccountNumber, bank_account_name: bankAccountName, bvn: bvn },
                setOpen, setSeverity, setToastMsg, setLoading,
                (res) => {
                    user.bank_name = bankName
                    user.bank_account_name = bankAccountName
                    user.bank_account_number = bankAccountNumber
                    user.bvn = bvn
                    cookies.setCookies('user', JSON.stringify(user), 0.5)
                    setBankDetails(false)
                    setTimeout(() => {
                        setOnboarding(true)
                    }, 2000);
                },
                "Account updated successfully"
            )
        }else {
            showError("Something went wrong, please try again")
        }
    }

    const showError = (msg, type) => {
        setSeverity(type)
        setToastMsg(msg)
        setOpen(true)
    }


    useEffect(() => {
        if (stringify_user.length < 10) {
            navigate('/')
        } else {
            setUser(JSON.parse(stringify_user))
        }
        if (!JSON.parse(stringify_user).bank_name) {
            setBankDetails(true)
        } else if (!JSON.parse(stringify_user).complete) {
            setOnboarding(true)
        }
        // eslint-disable-next-line
    }, [])
    return (
        <div className="dashboard-body">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Typography>
                        Your Balance
                    </Typography>
                    <Spacebox padding="10px" />
                    <CustomGrid grid={isMobile ? 1 : 4} gap="20px">
                        <Spacebox padding="10px" style={{ border: "1px solid #ffffff20", borderRadius: "20px" }}>
                            <Avatar style={{ width: 30, height: 30 }} src="/flags/us.png" alt="united states" />
                            <Spacebox padding="10px" />
                            <Typography>
                                United State Dollars
                            </Typography>
                            <Spacebox padding="0px" />
                            <Typography variant="h5" fontSize={isMobile ? "50px" : "70px"}>
                                {user && <span>
                                    <sup style={{ fontSize: 20 }}>$</sup>{user.balance > 9 ? "0" + user.balance : "00"}<small style={{ opacity: .5, fontSize: 20 }}>.00</small>
                                </span>}

                                {!user && <span>
                                    <sup style={{ fontSize: 20 }}>$</sup>00<small style={{ opacity: .5, fontSize: 20 }}>.00</small>
                                </span>}
                            </Typography>
                        </Spacebox>
                    </CustomGrid>
                </Spacebox>
            </div>
            <Spacebox padding="10px" />
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Typography>
                        Recent Settlements
                    </Typography>
                    <Spacebox padding="10px" />
                    <Typography>
                        | You have no transactions at this time
                    </Typography>
                </Spacebox>
            </div>
            <Spacebox padding="10px" />
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Typography>
                        Pending Tasks
                    </Typography>
                    <Spacebox padding="10px" />
                    <Typography>
                        | You have no pending tasks at this time
                    </Typography>
                </Spacebox>
            </div>
            {bankDetails && (
                <div className="glass" style={{ borderRadius: '0px', border: '0px solid transparent', position: 'fixed', zIndex: 200, top: 0, left: 0, width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="mid" style={{ background: 'var(--dark)', borderRadius: '20px' }}>
                        <Spacebox padding="40px">
                            <Typography variant="h4" className="bold">
                                Complete your account details
                            </Typography>
                            <Spacebox padding="10px" />
                            <Typography>
                                Provide the neccessary infomation below
                            </Typography>
                            <Spacebox padding="10px" />
                            <Spacebox padding="5px">
                                <small>Bank Name</small>
                            </Spacebox>
                            {checkCountry() ? (
                                <>
                                    <select
                                        name="bank-name"
                                        id="bank-name"
                                        value={bankName}
                                        onChange={(e) => {
                                            setBankName(e.target.value)
                                            setBankAccountName("")
                                            setBankAccountNumber("")
                                            setDisableInput(false)
                                        }}
                                    >
                                        {za_banks.names.map((bank, index) => (
                                            <option key={index} value={bank}>{bank}</option>
                                        ))}
                                    </select>
                                </>
                            ) : (
                                <>
                                    <select
                                        name="bank-name"
                                        id="bank-name"
                                        value={bankName}
                                        onChange={(e) => {
                                            setBankName(e.target.value)
                                            setBankAccountName("")
                                            setBankAccountNumber("")
                                            setDisableInput(false)
                                        }}
                                    >
                                        {banks.names.map((bank, index) => (
                                            <option key={index} value={bank}>{bank}</option>
                                        ))}
                                    </select>
                                </>
                            )}
                            <Spacebox padding="10px" />
                            <Spacebox padding="5px">
                                <small>Bank Account Number</small>
                            </Spacebox>
                            {checkCountry() ? (
                                <>
                                    <input
                                        type="number"
                                        placeholder="Bank Account Number"
                                        value={bankAccountNumber}
                                        onChange={(e) => setBankAccountNumber(e.target.value)}
                                    />
                                </>
                            ) : (
                                <>
                                    <input
                                        type="number"
                                        placeholder="Bank Account Number"
                                        value={bankAccountNumber}
                                        onChange={(e) => runAccountCheck(e.target.value)}
                                        disabled={disableInput}
                                    />
                                </>
                            )}
                            <Spacebox padding="10px" />
                            <Spacebox padding="5px">
                                <small>Bank Account Name</small>
                            </Spacebox>
                            <div style={{ padding: "15px 10px", border: "2px solid white", borderRadius: '10px', height: 20 }}>
                                <Typography>
                                    {checkCountry() && (user.firstname + " " + user.lastname).toUpperCase()}
                                    {!checkCountry() && bankAccountName}
                                </Typography>
                            </div>
                            <Spacebox padding="10px" />
                            {!checkCountry() && <>
                                <Spacebox padding="5px">
                                    <small>Bank Verification Number (BVN)</small>
                                </Spacebox>
                                <input
                                    type="number"
                                    placeholder="Bank Verification Number"
                                    value={bvn}
                                    onChange={(e) => setBVN(e.target.value)}
                                />
                                <Spacebox padding="10px" />
                            </>}
                            {!loading && <CustomButton style={{ background: 'var(--primary)', color: "white", padding: '15px 0px', borderRadius: '10px' }} className="fullwidth" handleClick={() => saveBankDetails()}>
                                Save my details
                            </CustomButton>}
                            {loading && <Flexbox justifyContent="center" alignItems="center">
                                <DotSpinner size={30} color="white" />
                            </Flexbox>}
                        </Spacebox>
                    </div>
                </div>
            )}
            {onboarding && (
                <div className="glass" style={{ borderRadius: '0px', border: '0px solid transparent', position: 'fixed', zIndex: 100, top: 0, left: 0, width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="mid" style={{ background: 'var(--dark)', borderRadius: '20px' }}>
                        {step === 1 && <Spacebox padding="40px">
                            <Typography variant="h4" className="bold">
                                Complete your KYC verification
                            </Typography>
                            <Spacebox padding="10px" />
                            <Typography>
                                In our attempt to protect our clients identity, we are required by law to verify your identity before you can begin testing on Tasksmania. <br /> <br />
                                This will require you to upload a copy of a government issued ID such as:
                            </Typography>
                            <ul>
                                <li>International Passport  <small style={{ display: 'inline-block', fontSize: '8px', textTransform: 'uppercase', letterSpacing: '3px' }}>(recommended)</small></li>
                                <li>National ID</li>
                                <li>Driver's license</li>
                            </ul>
                            <Spacebox padding="10px" />
                            <CustomButton style={{ background: 'var(--primary)', color: "white", padding: '15px 0px', borderRadius: '10px' }} className="fullwidth" handleClick={() => {
                                setStep(2)
                                setTimeout(() => {
                                    if (isMobile)
                                        navigate('/metamap')
                                    else
                                        showError("Continue on you mobile phone", "Error")
                                }, 3000);
                            }}>
                                Begin Identity Verification
                            </CustomButton>
                        </Spacebox>}
                        {step === 2 && <Spacebox padding="40px">
                            <Flexbox alignItems="center" justifyContent="center">
                                <div>
                                    <Flexbox alignItems="center" justifyContent="center">
                                        <DotSpinner size={30} color="white" />
                                    </Flexbox>
                                    <Spacebox padding="5px" />
                                    <small>Establishing connection to metaMap</small>
                                </div>
                            </Flexbox>
                        </Spacebox>}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;