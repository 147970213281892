import { Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Flexbox from "../components/Flexbox";
import { useState } from "react";
import { isMobile } from "react-device-detect";

const Payments = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const [query, setQuery] = useState("Successful")

    return (
        <div className="payments-body">
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Flexbox alignItems="center">
                        <Typography>
                            Filter By:
                        </Typography>
                        <Spacebox padding="10px" />
                        <select
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            style={{width: '150px'}}
                            className="mid-select"
                        >
                            <option value="Successful">Successful</option>
                            <option value="Pending">Pending</option>
                            <option value="Failed">Failed</option>
                        </select>
                    </Flexbox>
                    <Spacebox padding="10px" />
                    {!isMobile && <table style={{ width: '100%', overflow: 'hidden', borderRadius: '10px 10px 0px 0px'  }}>
                        <thead style={{ background: "#3e3e3ed1", }} >
                            <tr>
                                <td style={{ padding: '10px', textAlign: 'center' }}>DATE</td>
                                <td style={{ padding: '10px', textAlign: 'center' }}>AMOUNT</td>
                                <td style={{ padding: '10px', textAlign: 'center' }}>CHANNEL</td>
                                <td style={{ padding: '10px', textAlign: 'center' }}>FEE</td>
                            </tr>
                        </thead>
                    </table>}
                    {!isMobile && <Spacebox padding="10px" />}
                    <Typography style={{ textAlign: 'center' }}>
                        <b style={{ display: 'inline-block', borderRight: 'solid 2px white', paddingRight: '10px' }}>NO HISTORY</b> Your Payment histories will appear below
                    </Typography>
                </Spacebox>
            </div>
        </div>
    );
}

export default Payments;