import { Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import requests from "../handlers/requests";
import Toast from "../components/Toast";
import cookies from "../utilities/Cookies";
import { useNavigate } from "react-router-dom";
import Flexbox from "../components/Flexbox";
import { Check } from "@mui/icons-material";
import String from "../utilities/Strings";


const Tasks = ({ title }) => {

    document.querySelector("title").innerHTML = title
    const stringify_user = cookies.getCookies('user')
    const navigate = useNavigate()

    const [tasks, setTasks] = useState(null)

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');
    const [, setLoading] = useState(false)

    const getTasks = uid => {
        requests.makeGet(process.env.REACT_APP_SERVER + `tasks?uid=${uid}`,
            setOpen, setSeverity, setToastMsg, setLoading,
            (res) => {
                setTasks(res.data)
            },
            "Operation successful"
        )
    }

    useEffect(() => {
        if (stringify_user.length < 10) {
            navigate('/')
        } else {
            getTasks(JSON.parse(stringify_user).id)
        }
        // eslint-disable-next-line
    }, [])


    return (
        <div className="tasks-body">
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <div className="mode" style={{ borderRadius: '10px' }}>
                <Spacebox padding="20px">
                    <Typography>
                        Available Tasks
                    </Typography>
                    <Spacebox padding="10px" />
                    {!isMobile && <table style={{ width: '100%' }}>
                        <thead style={{ background: "#3e3e3ed1" }}>
                            <tr>
                                <td style={{ padding: '10px', textAlign: 'center' }}>TITLE</td>
                                <td style={{ padding: '10px', textAlign: 'center' }}>COMPANY</td>
                                <td style={{ padding: '10px', textAlign: 'center' }}>INDUSTRY</td>
                                <td style={{ padding: '10px', textAlign: 'center' }}>REWARD</td>
                                <td style={{ padding: '10px', textAlign: 'center' }}>DEADLINE</td>
                            </tr>
                        </thead>
                    </table>}
                    {!isMobile && <Spacebox padding="10px" />}
                    <Typography style={{ textAlign: 'center' }}>
                        <b style={{ display: 'inline-block', borderRight: 'solid 2px white', paddingRight: '10px' }}>NO TASK</b> No avaialable tasks at this time
                    </Typography>
                </Spacebox>
            </div>
            <Spacebox padding="10px" />
            {tasks && <div>
                {(tasks.length > 0) && <div className="mode" style={{ borderRadius: '10px' }}>
                    <Spacebox padding="20px">
                        <Typography>
                            Completed Tasks
                        </Typography>
                        <Spacebox padding="10px" />
                        {!isMobile && <table style={{ width: '100%' }}>
                            <thead style={{ background: "#3e3e3ed1" }}>
                                <tr>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>TITLE</td>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>COMPANY</td>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>INDUSTRY</td>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>REWARD</td>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>ASSIGNED</td>
                                    <td style={{ padding: '10px', textAlign: 'center' }}>COMPLETED</td>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task, index) => (
                                    <tr key={index}>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            {task.title}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            {task.company}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            {task.industry}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            ${task.reward}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            {task.assigned}
                                        </td>
                                        <td style={{ padding: '10px', textAlign: 'center' }}>
                                            {task.completed}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                        {isMobile && <div>
                            {tasks.map((task, index) => (
                                <div key={index}>
                                    <Flexbox alignItems="center" justifyContent="space-between">
                                        <Flexbox alignItems="center" justifyContent="center">
                                            <Flexbox justifyContent="center" alignItems="center" style={{ height: 30, width: 30, background: '#23b45123', borderRadius: 100 }}>
                                                <Check style={{ fontSize: '18px' }} className="green-text" />
                                            </Flexbox>
                                            <Spacebox padding="4px" />
                                            <div>
                                                <Typography style={{ fontSize: '15px' }}>
                                                    {String.shorten(task.title, 15)}
                                                </Typography>
                                                <small style={{ opacity: .5 }}>{task.company} • {String.shorten(task.completed, 12)}</small>
                                            </div>
                                        </Flexbox>
                                        <Typography style={{fontSize: '13px'}}>${task.reward}</Typography>
                                    </Flexbox>
                                    <Spacebox padding="15px" />
                                </div>
                            ))}
                        </div>}
                    </Spacebox>
                </div>}
            </div>}
            <Spacebox padding="10px" />
        </div>
    );
}

export default Tasks;