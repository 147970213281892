import { Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";

const Statements = ({ title }) => {

    document.querySelector("title").innerHTML = title

    return (
        <div className="statements-body">
            <Spacebox padding="20px" ></Spacebox>
            <Typography style={{textAlign: 'center'}}>
                <b style={{display: 'inline-block', borderRight: 'solid 2px white', paddingRight: '10px'}}>NO STATEMENT REPORT</b> Your statement report will appear below
            </Typography>
        </div>
    );
}

export default Statements;