const bank_codes = [
    {
        "Absa Bank": "632 005"
    },
    {
        "Capitec Bank": "470 010"
    },
    {
        "First National Bank (FNB)": "250 655"
    },
    {
        "Investec Bank": "580 105"
    },
    {
        "Nedbank": "198 765"
    },
    {
        "Standard Bank": "051 001"
    },
    {
        "African Bank": "430 000"
    },
    {
        "Mercantile Bank": "450 905"
    },
    {
        "TymeBank": "678 910"
    },
    {
        "Bidvest Bank": "679 000"
    },
    {
        "Sasfin Bank": "683 000"
    },
    {
        "Bank of Athens": "410 506"
    },
    {
        "RMB Private Bank": "222 026"
    },
    {
        "South African Post Bank (Post Office)": "460 005"
    },
    {
        "Hollard Bank": "585 001"
    },
    {
        "Discovery Bank": "679 000"
    },
    {
        "Standard Chartered Bank": "730 020"
    },
    {
        "Barclays Bank": "590 000"
    },
    {
        "Investec Bank Limited": "580 105"
    },
    {
        "Mercantile Bank Limited": "450 905"
    }
]

const bank_names = [
    "Absa Bank",
    "Capitec Bank",
    "First National Bank (FNB)",
    "Investec Bank",
    "Nedbank",
    "Standard Bank",
    "African Bank",
    "Mercantile Bank",
    "TymeBank",
    "Bidvest Bank",
    "Sasfin Bank",
    "Bank of Athens",
    "RMB Private Bank",
    "South African Post Bank (Post Office)",
    "Hollard Bank",
    "Discovery Bank",
    "Standard Chartered Bank",
    "Barclays Bank",
    "Investec Bank Limited",
    "Mercantile Bank Limited"
]

const za_banks = {codes: bank_codes, names: bank_names}

export default za_banks