import { Divider, IconButton } from "@mui/material";
import Spacebox from "./styles/Spacebox";
import Flexbox from "./Flexbox";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Close } from "@mui/icons-material";

const Sidebar = ({ openMenu, setOpenMenu }) => {

    const navigate = useNavigate()

    const menus = [
        {
            name: "Home",
            icon: "/assets/home.png",
            path: '/dashboard',
            action: () => {
                navigate('/dashboard')
                if (isMobile)
                    setOpenMenu(false)
            }
        },
        {
            name: "Tasks",
            icon: "/assets/tasks.png",
            path: '/dashboard/tasks',
            action: () => {
                navigate('/dashboard/tasks')
                if (isMobile)
                    setOpenMenu(false)
            }
        },
        {
            name: "Payments",
            icon: "/assets/payments.png",
            path: '/dashboard/payments',
            action: () => {
                navigate('/dashboard/payments')
                if (isMobile)
                    setOpenMenu(false)
            }
        },
        {
            name: "Statements",
            icon: "/assets/statements.png",
            path: '/dashboard/statements',
            action: () => {
                navigate('/dashboard/statements')
                if (isMobile)
                    setOpenMenu(false)
            }
        },
    ]


    return (
        <div className={`sidebar-holder ${openMenu ? "open-menu" : "close-menu"}`} style={{ height: "100vh", width: isMobile ? "0px" : "250px", zIndex: 10000 }}>
            <div className={`sidebar-placeholder ${openMenu ? "open-menu" : "close-menu"}`} style={{ height: "100%", width: "100%" }}></div>
            <div className={`sidebar mode ${openMenu ? "open-menu" : "close-menu"}`} style={{ height: "100vh", width: "250px", position: 'fixed', top: 0, left: 0, zIndex: 1000, background: isMobile ? "black" : "" }}>
                {!isMobile && <Spacebox padding="20px">
                    <img src="/logo-white.png" alt="tasksmania" style={{ width: 200 }} />
                </Spacebox>}
                {isMobile && <Spacebox padding="20px">
                    <Flexbox alignItems="center">
                        <IconButton onClick={() => setOpenMenu(false)}>
                            <Close />
                        </IconButton>
                        <Spacebox padding="1px" />
                        <div style={{ height: '15px', width: 1, backgroundColor: 'white' }}></div>
                        <Spacebox padding="2px" />
                        <img src="/logo-white.png" alt="tasksmania" style={{ width: 150 }} />
                    </Flexbox>
                </Spacebox>}
                <Divider />
                <Spacebox padding="20px">
                    {menus.map((menu, index) => (
                        <div key={index}>
                            <div onClick={menu.action} style={{ backgroundColor: (window.location.pathname === menu.path) ? "#6d1aa630" : "transparent", borderRadius: '10px', padding: '15px 10px', cursor: 'pointer', transition: '.5s ease' }} className="sidebar-menu">
                                <Flexbox alignItems="center">
                                    <img src={menu.icon} style={{ width: '20px', filter: "invert(1)" }} alt={menu.name} />
                                    <Spacebox padding="10px" />
                                    <span>{menu.name}</span>
                                </Flexbox>
                            </div>
                            <Spacebox padding="10px" />
                        </div>
                    ))}
                </Spacebox>
            </div>
        </div>
    );
}

export default Sidebar;