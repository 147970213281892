import { DotSpinner } from "@uiball/loaders";
import Flexbox from "../components/Flexbox";
import Toast from "../components/Toast";
import CustomButton from "../components/styles/Custombutton";
import Spacebox from "../components/styles/Spacebox";
import { useState } from "react";
import requests from "../handlers/requests";
import cookies from "../utilities/Cookies";
import { Divider, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Login = ({ title }) => {

    document.querySelector('title').innerHTML = title

    const navigate = useNavigate()

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('success');
    const [msg, setToastMsg] = useState('');

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)


    const handleLogin = () => {
        if (email.includes("@") && password.length > 7) {
            setLoading(true)
            requests.makePost(
                process.env.REACT_APP_SERVER + 'login',
                { email: email.toLowerCase(), password },
                setOpen,
                setSeverity,
                setToastMsg,
                setLoading,
                (res) => {
                    const user = res.data
                    delete user.video
                    delete user.id_front
                    delete user.bank_statement
                    if ("id_back" in user)
                        delete user.id_back

                    cookies.setCookies("user", JSON.stringify(user), 0.5)
                    if (user.complete)
                        navigate('/dashboard')
                    else
                        setTimeout(() => {
                            window.location.href = `https://www.tasksmania.com/contract?email=${user.email}&firstname=${user.firstname}&lastname=${user.lastname}`
                        }, 4000);
                },
                "Operation successful"
            )
        } else {
            setToastMsg("Incomplete information")
            setSeverity("error")
            setOpen(true)
        }
    }

    return (
        <div>
            <Toast open={open} setOpen={setOpen} severity={severity} timer={4000}>{msg}</Toast>
            <Flexbox className="full">
                <div className="mode hide-on-med-and-down" style={{ height: '100%', width: '50%' }}>
                    <Spacebox padding="20px">
                        <Spacebox padding="20px" style={{ textAlign: 'center' }}>
                            <img src="/logo-white.png" alt="logo" style={{ width: '150px' }} />
                        </Spacebox>
                        <Spacebox padding="10px" />
                        <img src="/assets/banner_side_img.png" style={{ width: '100%' }} alt="banner" />
                        <Spacebox padding="10px" />
                        <Typography fontSize="30px" textAlign="center">
                            <b>Boost your monthly income</b>
                        </Typography>
                        <Spacebox padding="4px" />
                        <Typography textAlign="center">
                            Try the ultimate side hustle. It’s easy, fast, and simple. Share your perspective on products and experiences and make money online from the comfort of your home.
                        </Typography>
                    </Spacebox>
                </div>
                <Flexbox justifyContent="center" alignItems="center" style={{ maxHeight: '100%', minHeight: "100%", width: '100%', overflowY: 'scroll' }}>
                    <Spacebox padding="20px" className="mid" style={{ margin: 'auto' }}>
                        {isMobile && <img src="/logo-white.png" alt="logo" style={{ width: '150px' }} />}
                        {isMobile && <Spacebox padding="10px" />}
                        <div style={{ width: '100%', borderRadius: '20px' }} className="mode">
                            <Spacebox padding={isMobile ? "10px" : "20px"}>
                                <Spacebox padding="10px">
                                    <Typography className="bold" variant="h5">
                                        Login Details
                                    </Typography>
                                </Spacebox>
                                <Divider light />
                                <Spacebox padding="10px">
                                    <Spacebox padding="5px">
                                        <small>Email</small>
                                    </Spacebox>
                                    <input
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="email"
                                        placeholder="Enter your email"
                                    />
                                    <Spacebox padding="10px" />
                                    <Spacebox padding="5px">
                                        <small>Password</small>
                                    </Spacebox>
                                    <input
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        type="password"
                                        placeholder="Enter your password"
                                    />
                                    <Spacebox padding="10px" />
                                    <CustomButton style={{ backgroundColor: "var(--primary)", padding: "15px 0px", color: 'white', borderRadius: "10px" }} className="fullwidth" handleClick={() => handleLogin()}>
                                        {!loading && <span>Sign In</span>}
                                        {loading && <Flexbox justifyContent="center">
                                            <DotSpinner size={20} color="white" />
                                        </Flexbox>}
                                    </CustomButton>
                                </Spacebox>
                            </Spacebox>
                        </div>
                        <Spacebox padding="10px">
                            <Typography textAlign="center">
                                Don't have an account? <Link to="/register"><u>Sign Up</u></Link>
                            </Typography>
                        </Spacebox>
                    </Spacebox>
                </Flexbox>
            </Flexbox>
        </div>
    );
}

export default Login;